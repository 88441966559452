<template>
	<div>
		<Nav @link="linkTo" :idx="6"></Nav>
		<div>
			<div v-for="(item, i) in list" :key="i">
			<div class="title">{{item.title}}</div>
			<div class="line_block"></div>
			<div class="pension_desc">{{item.desc}}</div>
			<div class="list_box">
				<div class="list_slider" v-for="(list, j) in item.sliders" :key="j">
					<img class="icon" :src="list.src" alt="">
					<div class="sub_title">{{list.subTitle}}</div>
					<div class="sub_desc">{{list.subDesc}}</div>
				</div>
			</div>
		</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
import Nav from '@/components/Nav'
import Footer from '@/components/Footer'

export default {
	name: 'productService3',
	components: {
		Nav,
		Footer
	},
	data () {
		return {
			list: [
				{
					title: '智慧社区',
					desc: '智慧社区是新形势下社会管理的一种新理念。利用物联网、云计算、移动互联网等新一代信息技术的集成应用,为社区居民提供一个安全、舒适、便利的现代化、智慧化生活环境,从而形成基于信息化、智能化社会管理与服务的一种新的管理形态的社区。',
					sliders: []
				},
				{
					title: '居家养老服务中心',
					desc: '居家养老服务是以家庭为核心，以社区为依托，老龄人群为服务对象，企事业服务机构为网点，信息化平台为支撑，以专业化服务为主要服务形式，积极发挥政府主导作用，广泛动员社会力量，充分利用社区资源为居住在家的老年人提供生活照料、医疗护理和文化娱乐、精神慰藉等方面服务的一种社会化养老服务形式。',
					sliders: [
						{
							src: require('@/assets/img/proIcon/pros_4.png'),
							subTitle: '智慧站点',
							subDesc: '智慧站点通过智能设备，自动识别前来站点的老人信息。'
						},
						{
							src: require('@/assets/img/proIcon/pros_5.png'),
							subTitle: '档案管理',
							subDesc: '老人个人信息，家属信息，健康状况，兴趣爱好等信息进行动态统一管理。'
						},
						{
							src: require('@/assets/img/proIcon/pros_6.png'),
							subTitle: 'sos管理',
							subDesc: '老人通过使用智能通讯终端一键拨打服务热线进行求助，平台能迅速获取老人位置，并对SOS警报进行响应。'
						},
						{
							src: require('@/assets/img/proIcon/pros_7.png'),
							subTitle: '呼叫中心',
							subDesc: '24小时接听老人诉求，解决了老年人不善于使用智能设备，一个电话全部解决。'
						},
						{
							src: require('@/assets/img/proIcon/pros_8.png'),
							subTitle: '居家安全',
							subDesc: '对接各类安防设备，如烟感、红外、摄像头等设备，出现意外时，社区及老人亲属可能第一时间接到报警。'
						},
						{
							src: require('@/assets/img/proIcon/pros_9.png'),
							subTitle: '远程健康监控',
							subDesc: '通过智能健康监测仪器（如血压仪）自助测量健康参数，数据自动上传到平台，通过分析，数据异常平台自动报警.'
						},
						{
							src: require('@/assets/img/proIcon/pros_10.png'),
							subTitle: '主动关怀',
							subDesc: '通过电话，小程序等方式将天气情况、保健护理、疾病预防、政府养老政策等发送给老人。'
						},
						{
							src: require('@/assets/img/proIcon/pros_11.png'),
							subTitle: '服务商家管理',
							subDesc: '根据老人的需求类别，选择相应的服务商，生成工单、派单，并对服务结果进行监管。'
						},
					]
				},
				{
					title: '智慧党群中心',
					desc: '党群服务中心是面向党员、基层干部、入党积极分子和周边群众开展党务政策咨询、办理党内业务、传播党建理论知识、提供党员政治生活的场所。通过信息化、智慧化手段，赋予新的内涵，方便党代表联络群众、帮扶生活困难党员群众、提供并组织党员志愿者服务、指导“两新”组织党建工作和本区域党员服务网络网格的建设、提供便民政务直通服务、受理反映社情民意、推进城乡区域化党建工作等。',
					sliders: [
						{
							src: require('@/assets/img/proIcon/pros_12.png'),
							subTitle: '指尖党建',
							subDesc: '借助智能手机强大的触达能力，为党员打造智慧党群微信小程序，使党为人民服务融入到党员的生活当中，并通过微信小程序，打造党群工作台等功能，实现党群间的快速协同和安全共享。'
						},
						{
							src: require('@/assets/img/proIcon/pros_13.png'),
							subTitle: '数字党建',
							subDesc: '以数字化规范党建业务，将发展党员、组织关系转接、组织换届等党务工作流程固化到平台中，通过信息化手段规范线下党务工作，保证党内政治生活的标准性、规范性和严谨性。'
						},
						{
							src: require('@/assets/img/proIcon/pros_14.png'),
							subTitle: '智慧党建',
							subDesc: '建设高厚智慧党建生态圈，实现各级党组织情况 “一图总览”，基层党建工作“一键调取”，确保各级党组织精准指挥，为组织部门宏观掌握本地党建生态，统筹调控全局提供有力支撑。'
						},
					]
				},
				{
					title: '智慧数据',
					desc: '从数据源接入，到数据采集、数据处理，再到数据分析和挖掘，打通数据生命周期的各个环节，实现数据填报、处理、分析一体化，为用户提供一站式数据服务。 平台提供了多种分析手段，在可视化分析方面支持复杂报表、Dashboard、3D可视化、大屏分析、GIS地图、预测挖掘等，以满足用户各种分析场景。',
					sliders: [
						{
							src: require('@/assets/img/proIcon/pros_15.png'),
							subTitle: '全端数据采集',
							subDesc: '强大的数据采集能力，覆盖移动端、PC 端和其他物联网设备（如摄像机、智慧餐台等）。'
						},
						{
							src: require('@/assets/img/proIcon/pros_16.png'),
							subTitle: '统一用户 ID',
							subDesc: '全触点数据采集，多源数据打通，构建全局统一的用户 ID 体系。'
						},
						{
							src: require('@/assets/img/proIcon/pros_17.png'),
							subTitle: '全渠道用户触达',
							subDesc: '对接微信生态、短信、电话等通道，在全渠道与用户实时互动。'
						},
						{
							src: require('@/assets/img/proIcon/pros_18.png'),
							subTitle: '用户标签体系',
							subDesc: '结合业务场景需求，可视化自助创建和维护标签，构建动态标签体系。'
						},
						{
							src: require('@/assets/img/proIcon/pros_19.png'),
							subTitle: '用户画像洞察',
							subDesc: '构建 360 度全景画像，还原用户（群）真实面貌，洞察特征偏好。'
						},
						{
							src: require('@/assets/img/proIcon/pros_20.png'),
							subTitle: '智能推荐',
							subDesc: '人工配置的规则推荐，结合 AI 算法的智能推荐，“千人千面”展示内容。'
						},
					]
				},
			]
		}
	},
	created () {},
	mounted () {
		window.scrollTo(0, 0);
	},
	methods: {
		linkTo (routerName) {
			this.$router.push(routerName)
		},
	}
}

</script>
<style lang='less' scoped>
@media screen and (max-width: 767px) {	// 移动端

}

@media screen and (min-width: 768px) and (max-width: 991px) {	// iPad
	
}

@media screen and (min-width: 992px) {	// PC端
	.title {
		margin-top: 80px;
		margin-bottom: 43px;
		text-align: center;
		font-size: 34px;
		font-family: SourceHanSansCN-Medium, SourceHanSansCN;
		font-weight: 400;
		color: #1F1836;
	}
	.line_block {
		margin: 0 auto;
		width: 51px;
		height: 2px;
		background: #2890FC;
	}
	.pension_desc {
		width: 1200px;
		letter-spacing: 2px;
		line-height: 1.5;
		margin: 40px auto 40px;
		font-size: 20px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: #666666;
		text-indent: 2em;
	}
	.list_box {
		width: 900px;
		margin: 40px auto 40px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		.list_slider {
			width: 200px;
			margin: 10px;
			box-sizing: border-box;
			padding: 25px 12px;
			background: #F8F8F8;
			.icon {
				width: 80px;
				display: block;
				margin: auto;
			}
			.sub_title {
				text-align: center;
				margin-top: 16px;
				font-size: 18px;
				font-family: SourceHanSansCN-Medium, SourceHanSansCN;
				font-weight: 500;
				color: #1A1A1A;
			}
			.sub_desc {
				letter-spacing: 1px;
				line-height: 1.5;
				margin-top: 25px;
				font-size: 14px;
				font-family: SourceHanSansCN-Regular, SourceHanSansCN;
				font-weight: 400;
				color: #999999;
				text-align: center;
			}
		}
	}
}
</style>